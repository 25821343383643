.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  max-width: 1500px;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: auto;
  min-height: 100vh;
}

.App h1,
h2,
h3 {
  margin-block-start: 0px !important;
  margin-block-end: 0px !important;
}

.App-logo {
  height: 18vmin;
  pointer-events: none;
}

.App-header {
  max-height: 18vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (min-width: 1300px) {
  .App-header {
    margin-left: 50px;
    margin-right: auto;
  }
}

.App-link {
  font-size: 18px;
  color: #5b5865;
}

.Main-nav {
  background-color: #cbc3e3;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: row;
  position: sticky;
  top: 0px;
  z-index: 1;
  align-items: center;
  justify-content: space-between;
}

.Nav-pill {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
}

.Nav-order-now {
  display: flex;
  background-color: #212121;
  border: none;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 200px;
  max-width: 200px;
  min-width: 200px;
  height: 60px;
  margin-right: 50px;
  color: white;
  text-decoration: none;
}

@media (max-width: 1300px) {
  .Nav-order-now {
    margin-left: auto;
    margin-right: 10px;
    width: 120px;
    max-width: 120px;
    min-width: 120px;
  }

  .Nav-pill {
    padding-bottom: 20px;
  }

  .App-link {
    font-size: 24px;
    color: whitesmoke;
  }
}

.Nav-order-now-pill {
  color: white;
  font-weight: 700;
}

.Nav-order-now-container {
  flex-grow: 1;
  flex-basis: 0;
}

.Nav-hamburger {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  margin-left: 10px;
}

.Announcement {
  background: #1da3ff;
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;
}

.Announcement-text {
  padding: 10px;
  font-size: 16px;
}

.Hero {
  display: flex;
  flex-direction: row;
  width: 1500px;
  max-width: 100%;
}

@media (max-width: 1300px) {
  .Hero {
    flex-wrap: wrap;
  }

  .Hero-image {
    align-items: center;
    width: 100%;
    justify-content: center;
  }

  .Hero-image img {
    display: block;
    width: 100%;
    max-width: 100%;
    object-fit: cover;
  }

  .Hero-content-header {
    font-size: 36px;
  }
}

@media (min-width: 1300px) {
  .Hero-content-header {
    font-size: 48px;
  }
}

.Hero-image {
  display: flex;
  float: left;
}

.Hero-content {
  display: flex;
  flex-direction: column;
  background-color: #cbc3e3;
  background-image: url("./resources/leaf-background.webp");
  background-blend-mode: multiply;
  justify-content: center;
  align-items: center;
}

.Hero-content-header {
  display: flex;
  font-weight: 700;
  padding-top: 25px;
}

.Hero-content-body {
  display: flex;
  flex-direction: column;
  font-size: 24px;
  padding: 25px;
}

@media (max-width: 1300px) {
  .Hero-content-body {
    font-size: 18px;
  }
}

.Menu {
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  max-width: 1500px;
  width: 100%;
  overflow: scroll;
}

.Menu-item {
  display: flex;
  position: relative;
  overflow: hidden;
  min-width: 360px;
}

.Menu-item img {
  float: left;
  width: 493px;
  height: 443px;
  object-fit: cover;
  max-width: 100%;
}

.Menu-item-diagonal-box {
  position: absolute;
  top: 352px;
  left: 1px;
  background-image: linear-gradient(45deg, #654ea3, #eaafc8);
  transform: skewY(-11deg);
  width: 492px;
  height: 150px;
  max-width: 100%;
}

.Menu-item-diagonal-box-text {
  font-size: 48px;
  color: white;
  font-weight: 700;
}

.Location-dropdown {
  position: absolute;
  z-index: 1000;
  background-color: #f8f8f8;
  border: 1px solid #cbc3e3;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(91, 88, 101, 0.2);
  padding: 8px 0;
  min-width: 200px;
  margin-top: 5px;
}

.Location-dropdown a {
  display: block;
  padding: 8px 16px;
  text-decoration: none;
  color: #5b5865;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.Location-dropdown a:hover {
  background-color: #212121;
  color: white;
}

.Location-order-header {
  color: #cbc3e3;
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: 18px;
  border-bottom: 1px solid #cbc3e3;
  padding-bottom: 5px;
}

.Location-option h3 {
  font-size: 16px;
  margin: 8px 0;
}

.Location-order-link {
  color: whitesmoke !important;
  padding-left: 10px;
}

.Location-specific-order {
  margin-top: 15px;
}

.Location-order-button {
  display: inline-block;
  background-color: #212121;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  transition: all 0.2s ease;
}

.Location-order-button:hover {
  background-color: #5b5865;
}

.Dropdown-trigger {
  cursor: pointer;
  position: relative;
}

.Dropdown-trigger:after {
  content: "▼";
  font-size: 10px;
  margin-left: 5px;
  display: inline-block;
}

.Desktop-dropdown {
  position: relative;
}

.Desktop-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f8f8f8;
  min-width: 200px;
  border: 1px solid #cbc3e3;
  border-radius: 10px;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(91, 88, 101, 0.2);
}

.Desktop-dropdown-content a {
  color: #5b5865;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 16px;
  font-weight: 500;
}

.Desktop-dropdown-content a:hover {
  background-color: #212121;
  color: white;
}

.Desktop-dropdown:hover .Desktop-dropdown-content {
  display: block;
}

.Locations {
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  max-width: 1500px;
  width: 100%;
  overflow: auto;
}

.Locations-item {
  display: flex;
  position: relative;
  overflow: hidden;
  min-width: 360px;
}

.Locations-item img {
  float: left;
  width: 750px;
  height: 500px;
  object-fit: cover;
  opacity: 0.4;
  max-width: 100%;
}

.Locations-details {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -80%);
  font-weight: 700;
  color: black;
  width: 100%;
}

@media (max-width: 600px) {
  .Locations-details {
    font-size: 36px;
  }

  .Locations-subdetails {
    font-size: 24px;
  }
}

@media (min-width: 601px) {
  .Locations-details {
    font-size: 50px;
  }
  .Locations-subdetails {
    font-size: 28px;
  }
}

.Locations-subdetails {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 10%);
  color: black;
  width: 100%;
  font-weight: 400;
}

.Locations-subdetails-link {
  color: black;
  width: 100%;
}

.Contact-form {
  display: flex;
  flex-direction: column;
  background-color: #cbc3e3;
  width: 100%;
  align-items: center;
  background-image: url("./resources/logo-faded.svg");
  background-blend-mode: multiply;
  background-position: center;
  background-size: 120px;
}

.Contact-form-header {
  display: flex;
  font-weight: 700;
  padding-top: 25px;
  padding-bottom: 25px;
}

@media (max-width: 1300px) {
  .Contact-form-header {
    font-size: 36px;
  }
}

@media (min-width: 1300px) {
  .Contact-form-header {
    font-size: 48px;
  }
}

.Contact-form-label {
  display: flex;
  font-size: 20px;
}

.Contact-form-input {
  margin-bottom: 10px;
  display: flex;
  height: 30px;
  width: 300px;
  border-color: #5b5865;
  outline: none;
  border-radius: 5px;
  border-width: 1px;
  font-size: 16px;
}

.Contact-form-text {
  margin-bottom: 10px;
  display: flex;
  max-width: 300px;
  min-width: 300px;
  border-color: #5b5865;
  outline: none;
  border-radius: 5px;
  border-width: 1px;
  min-height: 50px;
  max-height: 300px;
  font-size: 16px;
}

.Contact-form-submit {
  display: flex;
  background-color: #212121;
  border: none;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 200px;
  max-width: 200px;
  min-width: 200px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  color: white;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;

  &:disabled {
    background-color: #5b5865;
  }
}

.Social-board {
  padding-top: 10px;
  width: 100%;
}

.Social-board > span {
  max-width: 1500px !important;
}

.Social-board > span span {
  overflow-y: hidden !important;
}

.Footer {
  background-color: #262626;
  display: flex;
  height: 200px;
  border: none;
  max-width: 1500px;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.Footer-header {
  display: flex;
  color: #cbc3e3;
  font-size: 48px;
  font-weight: 700;
}

@media (min-width: 1300px) {
  .Footer-header {
    font-size: 30px;
  }
}

@media (max-width: 1300px) {
  .Footer-header {
    font-size: 20px;
  }
}

.Footer-social {
  display: flex;
}

.Social-icon {
  max-width: 30px;
  max-height: 30px;
  margin: 10px;
}

.Footer-copyright {
  color: #cbc3e3;
}

.Footer-site-disclosure {
  color: #cbc3e3;
  font-size: 12px;
}

.Privacy-disclosure {
  background-color: #cbc3e3;
  padding-top: 25px;
  padding-bottom: 25px;
  flex-grow: 1;
}

.Privacy-disclosure-header {
  font-size: 36px;
}

@media (min-width: 1300px) {
  .Privacy-disclosure-header {
    font-size: 48px;
  }
}

.Privacy-disclosure-body {
  font-size: 24px;
  margin: 10px;
}

.Privacy-disclosure-body a {
  color: #5b5865;
}

@media (max-width: 1300px) {
  .Privacy-disclosure-body {
    font-size: 18px;
  }
}

.Team-portal {
  background-color: #cbc3e3;
  padding-top: 25px;
  padding-bottom: 25px;
  flex-grow: 1;
  width: 100%;
}

.Team-portal-header {
  font-size: 36px;
}

@media (min-width: 1300px) {
  .Team-portal-header {
    font-size: 48px;
  }
}

.Team-portal-subheader {
  font-size: 24px;
  padding-top: 10px;
}

@media (min-width: 1300px) {
  .Team-portal-subheader {
    font-size: 30px;
  }
}

.Team-portal-body a {
  color: #5b5865;
}

.Team-portal-body {
  font-size: 24px;
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (max-width: 1300px) {
  .Team-portal-body {
    font-size: 18px;
  }
}

.Team-portal-social {
  display: flex;
  justify-content: center;
}

.Team-portal-locations {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.Team-portal-location-item {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 10px;
}

.Team-portal-management {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.Team-portal-management-item {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 10px;
}

#react-burger-menu-btn {
  width: 50px !important;
}

.bm-menu {
  background: #262626;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  opacity: 0.9;
}

.bm-menu-wrap {
  width: 300px;
  top: 0px;
  left: 0;
  background: #262626;
}

.bm-cross {
  background: #bdc3c7;
}

.bm-overlay {
  background: transparent !important;
  top: 0px;
  left: 0;
}

.disable-scroll {
  overflow: hidden;
}
